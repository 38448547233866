// i18next-extract-mark-ns-start not-found-page
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import { Button } from "@/components/ui";
import { Layout } from "@/components/layout";
import NotFoundIllustration from "@/assets/svgs/not-found.svg";
import ArrowIcon from "@/assets/svgs/icons/arrow.svg";

import * as styles from "./404.module.css";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Layout showFooter={false}>
      <section className={styles.noFoundSection}>
        <div className={styles.noFoundSectionDetails}>
          <h1 className={styles.title}>{t("not_found.title")}</h1>
          <p className={styles.aboutPageText}>{t("not_found.about_page")}</p>
          <p className={styles.description}>{t("not_found.description")}</p>
          <Button local link href="/" icon={<ArrowIcon />}>
            {t("not_found.back_button")}
          </Button>
        </div>
        <NotFoundIllustration />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "404-page"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFound;
